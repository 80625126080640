<template>
  <v-row>
    <v-col
      cols="12"
    >
      <base-card>
        <v-card-text>
          <v-card-title>
            Listados Credenciales TEF
            <v-spacer/>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            />
            <v-spacer/>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="details"
            :search="search"
            class="elevation-1"
          >
            <template v-slot:[`item.type_tef`]="{ item }">
              <div
                v-if="item.type_tef === 1"
              >
                <span
                  class="ml-0 mr-2 black--text v-chip v-chip--label
                theme--light v-size--small yellow lighten-4"
                >
                  <span class="v-chip__content"> CREDIBANCO </span>
                </span><strong>Smartpos</strong>
              </div>
              <div
                v-else-if="item.type_tef === 2"
              >
                <span
                  class="ml-0 mr-2 black--text v-chip v-chip--label
                theme--light v-size--small dark  lighten-4"
                >
                  <span class="v-chip__content"> CREDIBANCO </span>
                </span><strong>Aplicaciones</strong>
              </div>
              <div
                v-else-if="item.type_tef === 3"
              >
                <span
                  class="ml-0 mr-2 black--text v-chip v-chip--label
                theme--light v-size--small blue lighten-4"
                >
                  <span class="v-chip__content"> CREDIBANCO </span>
                </span><strong>Smartpos Sin Id</strong>
              </div>
              <div
                v-else-if="item.type_tef === 4"
              >
                <span
                  class="ml-0 mr-2 black--text v-chip v-chip--label
                theme--light v-size--small purple  lighten-4"
                >
                  <span class="v-chip__content"> CREDIBANCO </span>
                </span><strong>Tramas</strong>
              </div>
            </template>

            <template v-slot:[`item.terminales`]="{ item }">
              <router-link
                :to="`/app/administration/tefConfigTerminales?Tef=${item.type_tef}&Client=${item.client}`"
                @click.native=""
              >
                <v-icon
                  class="mr-2"
                  color="info darken-3"
                  style="cursor: pointer"
                >
                  mdi-desktop-classic
                </v-icon>
              </router-link>
            </template>

          </v-data-table>
        </v-card-text>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>

  export default {
    name: 'ShowCredentialsTef',
    data () {
      return {
        search: '',
        headers: [
          {
            text: 'Nombre Compañía',
            value: 'client',
          },
          {
            text: 'Tipo Tef',
            value: 'type_tef',
            align: 'center',
          },
          {
            text: 'Terminales',
            value: 'terminales',
            align: 'center',
          },
        ],
      }
    },

    computed: {
      details () {
        return this.$store.state.credentialsTef.listCredentials
      },
    },

    created () {
      this.getListCredentials()
    },
    methods: {
      async getListCredentials () {
        await this.$store.dispatch('credentialsTef/getListCredentials')
      },
    },
  }
</script>

<style scoped>

</style>
